import styled from '@emotion/styled';

const Root = styled('div')(({ theme }) => ({
  padding: '1.2rem 0 0 0',
  p: {
    color: theme.colors.gray.medium,
    fontSize: '1.2rem',
    opacity: '0.5',
  },
}));

export default Root;
