import Root from './primitives/Root';

type Props = {
  copyright?: string;
  publisher?: string;
};

function CopyrightInfo({ copyright, publisher }: Props) {
  return copyright || publisher ?
      <Root>
        {copyright === publisher ?
          <p>{copyright}</p>
        : <>
            {copyright ?
              <p>{copyright}</p>
            : null}
            {publisher ?
              <p>{publisher}</p>
            : null}
          </>
        }
      </Root>
    : null;
}

export default CopyrightInfo;
