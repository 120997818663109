import ArtistSongsHead from './Head';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getAlbumPath,
  getCurrentAlbumId,
  getCurrentAlbumReleaseDate,
  getCurrentAlbumTitle,
  getCurrentAlbumTracks,
} from 'state/Albums/selectors';
import {
  getCurrentArtistId,
  getCurrentArtistName,
} from 'state/Artists/selectors';
import { getSiteUrl } from 'state/Config/selectors';

const mapStateToProps = createStructuredSelector({
  albumId: getCurrentAlbumId,
  albumTitle: getCurrentAlbumTitle,
  albumTracks: getCurrentAlbumTracks,
  artistId: getCurrentArtistId,
  artistName: getCurrentArtistName,
  pagePath: getAlbumPath,
  releaseDate: getCurrentAlbumReleaseDate,
  siteUrl: getSiteUrl,
  tracks: getCurrentAlbumTracks,
});

export default connect(mapStateToProps)(ArtistSongsHead);
