import factory from 'state/factory';
import {
  buildCatalogUrl,
  buildImageUrl,
} from 'utils/mediaServerImageMaker/mediaServerImageUrl';
import { getMediaServerUrl } from 'state/Config/selectors';
import { slugify } from 'utils/string';
import { Track } from './types';

const store = factory();

export function getTrackUrl({
  artistId,
  artistName,
  trackId,
  trackName,
}: {
  artistId: number;
  artistName: string;
  trackId: number;
  trackName: string;
}) {
  if (!artistId || !trackId) return null;
  return `/artist/${slugify(artistName)}-${artistId}/songs/${slugify(
    trackName,
  )}-${trackId}`;
}

export function mapTracks(tracks: Array<Track>): Array<Track> {
  const mediaServerUrl = getMediaServerUrl(store.getState());

  return tracks.map((track: Track) => ({
    ...track,
    id: track.trackId || track.id,
    imageUrl: buildImageUrl(
      buildCatalogUrl(mediaServerUrl, {
        id: track.trackId || track.id,
        resourceType: 'track',
      }),
    )(),
    trackId: track.trackId || track.id,
    trackTitle: track.trackTitle ?? track.title ?? '',
    type: 'track',
  }));
}
