import AlbumHero from './AlbumHero';
import {
  backgroundColor,
  noLogo,
  primaryBackgroundSrc,
} from 'state/Hero/selectors';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  getCurrentAlbumExplicit,
  getCurrentAlbumId,
  getCurrentAlbumReleaseDate,
  getCurrentAlbumTitle,
  getCurrentAlbumTracks,
} from 'state/Albums/selectors';
import {
  getCurrentArtistId,
  getCurrentArtistIsFavorite,
  getCurrentArtistName,
} from 'state/Artists/selectors';
import {
  getCustomRadioEnabled,
  getOnDemandEnabled,
} from 'state/Features/selectors';
import { getMediaServerUrl, getSiteUrl } from 'state/Config/selectors';
import { getSubscriptionType } from 'state/Entitlements/selectors';
import { localize } from 'redux-i18n';
import {
  resetHero,
  setHasHero,
  setHeroPremiumBackground,
} from 'state/Hero/actions';

import { toggleFavoriteArtist } from 'state/Artists/actions';

export default localize('translate')(
  connect(
    createStructuredSelector({
      albumId: getCurrentAlbumId,
      albumTitle: getCurrentAlbumTitle,
      artistId: getCurrentArtistId,
      artistName: getCurrentArtistName,
      backgroundColor,
      customRadioEnabled: getCustomRadioEnabled,
      explicitLyrics: getCurrentAlbumExplicit,
      isFavorite: getCurrentArtistIsFavorite,
      mediaServerUrl: getMediaServerUrl,
      noLogo,
      onDemandEnabled: getOnDemandEnabled,
      primaryBackgroundSrc,
      releaseDate: getCurrentAlbumReleaseDate,
      siteUrl: getSiteUrl,
      tracks: getCurrentAlbumTracks,
    }),
    {
      setHeroPremiumBackground,
      resetHero,
      setHasHero,
      subscriptionType: getSubscriptionType,
      toggleFavoriteArtist,
    },
  )(AlbumHero),
);
