import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { getArtistUrl } from 'views/Artist/helpers';
import { getScaledImageUrlById, join as joinURL } from 'utils/url';
import { getTrackUrl } from 'state/Tracks/helpers';
import { Helmet } from 'react-helmet';
import {
  metaResolveUrl as resolveUrl,
  translateKeywords,
} from 'utils/metaUtils';
import type { Track } from 'state/Albums/types';

type Props = {
  albumTracks: Array<Track>;
  albumId: number;
  albumTitle: string;
  artistId: number;
  artistName: string;
  pagePath: string;
  releaseDate: number;
  siteUrl: string;
  tracks: Array<any>;
};

function Head({
  albumTracks,
  albumId,
  albumTitle,
  siteUrl,
  pagePath,
  artistId,
  artistName,
  tracks,
  releaseDate,
}: Props) {
  const translate = useTranslate();

  const keywords = translateKeywords(
    translate,
    'Music, New, Best Station, Listen, Play, Track, Tracks, List, Listing, Lyrics, Download, iHeartRadio, iHeart, Radio',
  );
  const artistUrl = getArtistUrl(artistId, artistName);
  const title = `${artistName} - ${albumTitle}`;

  const description = albumTracks
    .slice(0, 3)
    .map(track => track?.title ?? '')
    .join(', ');

  const tracksMeta = tracks.map(({ id, title: trackName }) => {
    const trackUrl = getTrackUrl({
      artistId,
      artistName,
      trackId: id,
      trackName,
    });

    return {
      content: joinURL(siteUrl, trackUrl!),
      property: 'music:song',
    };
  });

  const albumUrl = resolveUrl(siteUrl, pagePath);

  const released = releaseDate ? new Date(releaseDate).toISOString() : null;

  return (
    <div>
      <GenericHead
        deeplink={`play/custom/album/${albumId}`}
        description={description}
        image={getScaledImageUrlById(albumId, 'album')}
        legacyDeeplink={`play/custom/album/${albumId}`}
        metaDescription={description}
        ogType="music.album"
        title={title}
        twitterCard="summary_large_image"
      />
      <Helmet
        meta={[
          {
            content: [albumTitle, artistName, ...keywords.split(',')].join(','),
            name: 'keywords',
          },
          { content: 'audio/vnd.facebook.bridge', property: 'og:audio:type' },
          { content: albumUrl, property: 'og:audio' },
          { content: joinURL(siteUrl, artistUrl!), property: 'music:musician' },
          { content: released!, property: 'music:release_date' },
          ...tracksMeta,
        ]}
      />
    </div>
  );
}

export default Head;
